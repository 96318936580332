import { OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import DescriptionIcon from '../../../../assets/svg/description.svg';
import { getBreakpoint } from '../../../../base/style';
import Button from '../../../../components/Button/index';
import Card from '../../../../components/Card/index';
import { FullPageLoader } from '../../../../components/Loading/index';
import PageTitle from '../../../../components/PageTitle/index';
import ToggleButton from '../../../../components/ToggleButton/index';
import { P1, P2 } from '../../../../components/Typography/index';
import { getTenantById } from '../../../../redux/entities/entities.selectors';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import {
  signRepositRequested,
  SIGN_REPOSIT_STORE_KEY,
} from '../../../../redux/order-customer-actions/order-customer-actions.actions';
import {
  getAllActionsByOrderCustomerId,
  getOrderCustomerActionById,
} from '../../../../redux/order-customer-actions/order-customer-actions.selectors';
import { getCurrentOrderCustomer } from '../../../../redux/order/order.selectors';
import { getCurrentTenancyUser } from '../../../../redux/tenancy-user/tenancy-user.selector';
import TermsModal from './terms-modal';

interface SignProps {}

const Action = styled.div`
  margin-bottom: 120px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
`;

const ActionInfo = styled(P1)`
  font-size: 17px;
  margin: 0 20px 20px 0;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 20px;
  }
`;

const ShowTerms = styled.div`
  color: ${(props) => props.theme.colors.primaryBrand};
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-weight: bold;
  text-decoration-line: underline;
  line-height: 1.5;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 2px;
  cursor: pointer;

  &:before {
    background: transparent url(${DescriptionIcon}) no-repeat center center;
    background-size: 24px;
    content: '';
    display: block;
    height: 24px;
    margin: 0 7px 0 0;
    width: 24px;
  }
`;

const ConfirmContainer = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  margin-bottom: 24px;
`;

const Sign: React.FC<SignProps> = () => {
  const [canSubmit, setCanSubmit] = useState<boolean | undefined>();
  const toggleCanSubmit = () => setCanSubmit(!canSubmit);
  const dispatch = useDispatch();
  const { order, customer, nextAction, id: orderCustomerId } = useSelector(getCurrentOrderCustomer);
  const signRepositLoadingSelector = createLoadingSelector([SIGN_REPOSIT_STORE_KEY]);
  const isSignRepositLoading = useSelector(signRepositLoadingSelector);
  const signTerms = () => dispatch(signRepositRequested(customer.id, order.id));
  // not ideal - but the type above thinks it could be undefined
  const action = useSelector(getOrderCustomerActionById(nextAction ? nextAction.orderCustomerActionIds[0] : ''));

  const allActions = useSelector(getAllActionsByOrderCustomerId(orderCustomerId));
  const addendumAction = allActions.find((action) => action.typeId === OrderCustomerActionDTOTypeIdEnum.REPOSITSIGNADDENDUM);
  const nextStep = addendumAction ? 'Sign Addendum' : 'Payment';
  const actionHasTermsLink = get(action, 'details.termsLink');
  const maximumPolicyCover = get(action, 'details.termsProperties.maximumPolicyCover');

  const currentTenancyUser = useSelector(getCurrentTenancyUser);
  const currentTenancyUserId = get(currentTenancyUser, 'id', '');
  const currentTenant = useSelector(getTenantById(currentTenancyUserId));

  const [showTerms, setShowTerms] = useState<boolean>(false);

  if (nextAction && !nextAction.actionable) {
    return (
      <Container>
        <Row>
          <Col lg={10} push={{ lg: 1 }}>
            <PageTitle>Waiting for other tenants...</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col lg={10} push={{ lg: 1 }}>
            {isSignRepositLoading ? (
              <FullPageLoader />
            ) : (
              <Card>
                <P1>
                  We're just waiting for the other tenants to confirm before you can complete the purchase of your Reposit. We'll
                  notify you via email and SMS when this is ready.
                </P1>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      {showTerms ? <TermsModal maximumPolicyCover={maximumPolicyCover} closeTerms={() => setShowTerms(false)} /> : null}
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <PageTitle>Agree to our Terms</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          {isSignRepositLoading ? (
            <FullPageLoader />
          ) : (
            <Card>
              <P1>
                Nearly there! We just need you to agree to our legal agreements which cover the points we mentioned in the
                previous step.
              </P1>
              <P1>
                <ShowTerms onClick={() => setShowTerms(true)}>Read our terms</ShowTerms>
              </P1>
              <P1>
                If you are unsure of any points or have further questions please contact our support team via web chat or email.
              </P1>
              <ConfirmContainer>
                <ToggleButton name="confirm" onToggle={toggleCanSubmit} label="Accept terms" />
              </ConfirmContainer>
              {!actionHasTermsLink ? (
                <>
                  <P2>
                    By accepting the terms and conditions, a version of the document will be created and e-signed with the
                    following wording: I
                    {currentTenant ? `, ${currentTenant.firstName} ${currentTenant.lastName},` : ', [your name],'} have read and
                    agree to the Reposit terms and conditions.
                  </P2>
                  <P2>
                    Your IP address will be recorded as part of the e-signature process and will be visible on the created
                    document. This will be available to you when your Reposit is active.
                  </P2>
                </>
              ) : (
                <></>
              )}
            </Card>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={10} push={{ lg: 1 }}>
          <Action>
            <ActionInfo>Next: {nextStep}</ActionInfo>
            <Button type="submit" disabled={isSignRepositLoading || !canSubmit} onClick={signTerms}>
              Continue
            </Button>
          </Action>
        </Col>
      </Row>
    </Container>
  );
};

export default Sign;
