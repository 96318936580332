import { unwrapResult } from '@reduxjs/toolkit';
import { ClaimDTOStatusEnum, OrderDTOStatusIdEnum } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useAppDispatch } from '../..';
import Button from '../../components/Button';
import Card from '../../components/Card';
import FlashMessage, { FlashState } from '../../components/FlashMessage';
import FieldWithLabel from '../../components/FormFields/FieldWithLabel';
import TextArea from '../../components/FormFields/TextArea';
import { FileUpload, FileUploadProgress } from '../../components/Library/FileUpload/FileUpload';
import SecondaryPanel from '../../components/SecondaryPanel';
import { Header3, Header4, P1, P2 } from '../../components/Typography';
import { CLAIM_UPLOAD_INFO } from '../../constants/claim';
import { CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY, UPDATE_CLAIM_RESPONSE_STORE_KEY } from '../../redux/claim/claim.actions';
import {
  createClaimResponseDocumentThunk,
  deleteClaimResponseDocumentThunk,
  updateDisputeMessageThunk,
} from '../../redux/claim/claim.thunk';
import { ClaimEntity, DocumentEntity } from '../../redux/entities/entities.types';
import { setFlashMessage } from '../../redux/flash-messages/flash-messages.actions';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { AppState } from '../../redux/root.reducer';
import { getArbitrationAdminFeeOrder } from '../../redux/selectors/mediation.selectors';
import ArbitrationPayment from '../ArbitrationPayment';
import { useFlashMessage } from '../FlashMessage';

interface ClaimDisputeProps {
  claim: ClaimEntity;
  claimResponseDocuments: DocumentEntity[];
  hasGoneBack: boolean;
  setHasGoneBack: (hasGoneBack: boolean) => void;
}

export interface ClaimDisputeFormValues {
  message: string;
}

const Wrapper = styled.div`
  padding: 0 40px 24px;
`;

const Action = styled.div`
  text-align: right;
  padding: 24px 40px;
`;

const DisputeCard = styled(Card)`
  position: relative;
  margin: 7px 0 0;

  &:before {
    background: #fff;
    content: '';
    display: block;
    position: absolute;
    margin: -10px 242px 0 0;
    transform: rotate(45deg);
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
  }
`;

const Schema = Yup.object().shape({
  message: Yup.string().required('Message is required'),
});

enum DisputeStep {
  DOCUMENTS_UPLOAD,
  ARBITRATION_PAYMENT,
}

const Notifier = styled(P2)`
  cursor: pointer;
  text-decoration: underline;
  margin-top: 4px;
  display: inline-block;
`;

const UploadInfo = () => <Notifier data-tip={CLAIM_UPLOAD_INFO}>What can I upload?</Notifier>;

const ClaimDispute: React.FC<ClaimDisputeProps> = ({ claim, claimResponseDocuments, hasGoneBack, setHasGoneBack }) => {
  const dispatch = useAppDispatch();
  const [fileUploadProgress, setFileUploadProgress] = useState<FileUploadProgress | undefined>();
  const isLoadingSelector = createLoadingSelector([CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY, UPDATE_CLAIM_RESPONSE_STORE_KEY]);
  const isLoading = useSelector(isLoadingSelector);
  let orderId: string = '';

  // const updateClaimResponse = (fields: ClaimDisputeFormValues) => {
  //   if (claimResponseDocuments && !claimResponseDocuments.length) {
  //     setMessageForRequest(fields.message);
  //     dispatch(setIsClaimResponseUpdateModalShowing(true));
  //   } else {
  //     dispatch(updateClaimResponseRequested({ message: fields.message, claimId, claimRespondentId, claimResponseId }));
  //   }
  // };

  // need modal if there are no documents
  const updateDisputeMessage = async (values: ClaimDisputeFormValues) => {
    const payload = { claimId: claim.id, message: values.message };
    await dispatch(updateDisputeMessageThunk(payload)).then(unwrapResult);
    setHasGoneBack(false);
  };

  // CHANGE ME ---------------------------
  const hasTenantResponded = !!claim.tenantDisputeMessage;

  const order = useSelector((state: AppState) => getArbitrationAdminFeeOrder(state, claim.id));
  // resolve dispute step
  let step: DisputeStep = DisputeStep.DOCUMENTS_UPLOAD;
  if (claim.status === ClaimDTOStatusEnum.AWAITINGARBITRATIONADMINFEE && hasTenantResponded && !hasGoneBack) {
    // only send to the arbitration payment step if the order is PENDING
    // the arbitration fee is charged once per tenancy
    // this order is now on the claim

    if (order && order.statusId === OrderDTOStatusIdEnum.PENDING) {
      orderId = order.id;
      step = DisputeStep.ARBITRATION_PAYMENT;
    }
  }

  const [flashMessage, onDismissFlashMessage] = useFlashMessage([CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY]);

  const onFileError = (error: string) =>
    dispatch(setFlashMessage({ key: CREATE_CLAIM_RESPONSE_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));

  const resolveDisputeStep = () => {
    if (step === DisputeStep.DOCUMENTS_UPLOAD) {
      return (
        <Formik
          initialValues={{ message: claim.tenantDisputeMessage || '' }}
          validationSchema={Schema}
          onSubmit={updateDisputeMessage}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }: FormikProps<ClaimDisputeFormValues>) => (
            <form onSubmit={handleSubmit}>
              <DisputeCard
                flush
                flashMessage={
                  flashMessage ? (
                    <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={5000} payload={flashMessage} />
                  ) : undefined
                }
              >
                <Fragment>
                  <Wrapper>
                    <Header3>Formal dispute</Header3>
                    <P1>
                      To continue, please provide a reason for disputing your charges in the text box below. Your response will
                      represent all tenants on this Reposit, and both your comments and evidence will be visible to the agent or
                      landlord. Any previously submitted evidence will also be included.
                    </P1>
                    <Header4>What are your reasons for disputing these charges?</Header4>
                    <FieldWithLabel touched={touched.message} error={errors.message} style={{ fontSize: '1em' }}>
                      <TextArea value={values.message} onChange={handleChange} onBlur={handleBlur} name="message" />
                    </FieldWithLabel>
                  </Wrapper>
                  <SecondaryPanel>
                    <Header4>Do you have any evidence to support this?</Header4>
                    <P2>
                      It will help your appeal against these charges if you have evidence that proves your argument.
                      <br />
                      Upload any images or documents you feel are applicable here.
                    </P2>
                    <P2>
                      Please upload any documents here you feel are relevant. Examples include email correspondence, text messages
                      or date-stamped photos. Everything you upload here will be sent to the independent adjudicator for them to
                      consider.
                    </P2>
                    <UploadInfo />
                    <FileUpload
                      documents={claimResponseDocuments}
                      uploadFile={(file) => {
                        return dispatch(createClaimResponseDocumentThunk({ claimId: claim.id, file })).then(unwrapResult);
                      }}
                      deleteFile={async (documentId: string) => {
                        await dispatch(deleteClaimResponseDocumentThunk({ documentId, claimId: claim.id })).then(unwrapResult);
                      }}
                      showDeleteButton
                      onError={onFileError}
                      required={false}
                      fileUploadProgress={fileUploadProgress}
                      setFileUploadProgress={setFileUploadProgress}
                    />
                  </SecondaryPanel>
                  <Action>
                    <Button disabled={isLoading}>Proceed To Payment</Button>
                  </Action>
                </Fragment>
              </DisputeCard>
            </form>
          )}
        </Formik>
      );
    }

    if (step === DisputeStep.ARBITRATION_PAYMENT) {
      return <ArbitrationPayment orderId={orderId} />;
    }

    return <>Something went wrong</>;
  };

  return resolveDisputeStep();
};

export default ClaimDispute;
