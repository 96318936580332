import React from 'react';
import styled from 'styled-components';

interface OptionProps {
  checked: boolean;
  error?: string;
  touched?: boolean;
  direction?: 'horizontal' | 'vertical';
}

const OptionContainer = styled.div<{ error?: string; touched?: boolean; direction?: 'horizontal' | 'vertical' }>`
  align-items: ${(props) => (props.direction === 'vertical' ? 'flex-start' : 'center')};
  display: flex;
  flex-direction: ${(props) => (props.direction === 'vertical' ? 'column' : 'row')};
  padding: 8px 0 8px;
  border: 1px solid ${(props) => (props.error && props.touched ? props.theme.colors.negative : 'rgba(0, 0, 0, 0)')};
  border-radius: 3px;
  padding-left: ${(props) => (props.error && props.touched ? '8px' : '0')};

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;

const Option = styled.div<OptionProps>`
  position: relative;
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: ${(props) => (props.direction === 'vertical' ? '0 0 12px 0' : '0 22px 0 0')};

  &:before {
    background: #fff;
    box-sizing: border-box;
    border: ${(props) =>
      props.checked ? `solid 5px ${props.theme.colors.primaryBrand}` : `solid 2px ${props.theme.colors.disabled}`};
    content: '';
    border-radius: 50px;
    display: block;
    flex: 0 0 18px;
    height: 18px;
    width: 18px;
  }

  &:hover,
  &:active {
    &:before {
      background: ${(props) => props.theme.colors.bgAccent};
      border: ${(props) =>
        props.checked ? `solid 5px ${props.theme.colors.primaryBrand}` : `solid 2px ${props.theme.colors.body}`};
    }
  }

  &:last-child {
    margin: 0;
  }
`;

const OptionLabel = styled.label`
  cursor: pointer;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  color: ${(props) => props.theme.colors.body};
  padding: 0 0 0 8px;
  width: 100%;
`;

interface Option {
  label: string;
  value: string;
}

interface RadioGroupProps {
  name: string;
  selected?: string;
  options: Option[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  touched?: boolean;
  direction?: 'horizontal' | 'vertical';
}

const Input = styled.input`
  width: 100%;
  left: 0;
  top: 0;
`;

export default ({
  selected = '',
  options,
  onChange,
  onBlur,
  name,
  error,
  touched,
  direction = 'horizontal',
}: RadioGroupProps) => {
  const result = options.map((option) => (
    <Option checked={selected === option.value} key={option.value} direction={direction}>
      <Input
        id={option.value}
        type="radio"
        name={name}
        value={option.value}
        checked={selected === option.value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <OptionLabel htmlFor={option.value}>{option.label}</OptionLabel>
    </Option>
  ));
  return (
    <OptionContainer error={error} touched={touched} direction={direction}>
      {result}
    </OptionContainer>
  );
};
