import { FetchRepositOfferParams, RespondToRepositOfferParams, AddRejectionReasonParams } from './reposit-offer.types';

export const FETCH_REPOSIT_OFFER_STORE_KEY = 'REPOSIT_OFFER/FETCH_REPOSIT_OFFER';
export const FETCH_REPOSIT_OFFER_API_REQUESTED = 'REPOSIT_OFFER/FETCH_REPOSIT_OFFER_API_REQUESTED';
export const FETCH_REPOSIT_OFFER_API_SUCCESS = 'REPOSIT_OFFER/FETCH_REPOSIT_OFFER_API_SUCCESS';
export const FETCH_REPOSIT_OFFER_API_FAILED = 'REPOSIT_OFFER/FETCH_REPOSIT_OFFER_API_FAILED';

export const RESPOND_TO_REPOSIT_OFFER_STORE_KEY = 'REPOSIT_OFFER/RESPOND_TO_REPOSIT_OFFER';
export const RESPOND_TO_REPOSIT_OFFER_API_REQUESTED = 'REPOSIT_OFFER/RESPOND_TO_REPOSIT_OFFER_API_REQUESTED';
export const RESPOND_TO_REPOSIT_OFFER_API_SUCCESS = 'REPOSIT_OFFER/RESPOND_TO_REPOSIT_OFFER_API_SUCCESS';
export const RESPOND_TO_REPOSIT_OFFER_API_FAILED = 'REPOSIT_OFFER/RESPOND_TO_REPOSIT_OFFER_API_FAILED';

export const ADD_REJECTION_REASON_STORE_KEY = 'REPOSIT_OFFER/ADD_REJECTION_REASON';
export const ADD_REJECTION_REASON_API_REQUESTED = 'REPOSIT_OFFER/ADD_REJECTION_REASON_API_REQUESTED';
export const ADD_REJECTION_REASON_API_SUCCESS = 'REPOSIT_OFFER/ADD_REJECTION_REASON_API_SUCCESS';
export const ADD_REJECTION_REASON_API_FAILED = 'REPOSIT_OFFER/ADD_REJECTION_REASON_API_FAILED';

export const SET_REPOSIT_OFFER_RESPONSE_MODAL_OPEN = 'REPOSIT_OFFER/SET_REPOSIT_OFFER_RESPONSE_MODAL_OPEN';

export function fetchRepositOfferRequested(payload: FetchRepositOfferParams) {
  return {
    type: FETCH_REPOSIT_OFFER_API_REQUESTED,
    payload,
  } as const;
}

export function fetchRepositOfferSuccess() {
  return {
    type: FETCH_REPOSIT_OFFER_API_SUCCESS,
  } as const;
}

export function fetchRepositOfferFailed(error: string) {
  return {
    type: FETCH_REPOSIT_OFFER_API_FAILED,
    payload: error,
  } as const;
}

export function respondToRepositOfferRequested(payload: RespondToRepositOfferParams) {
  return {
    type: RESPOND_TO_REPOSIT_OFFER_API_REQUESTED,
    payload,
  } as const;
}

export function respondToRepositOfferSuccess() {
  return {
    type: RESPOND_TO_REPOSIT_OFFER_API_SUCCESS,
  } as const;
}

export function respondToRepositOfferFailed(error: string) {
  return {
    type: RESPOND_TO_REPOSIT_OFFER_API_FAILED,
    payload: error,
  } as const;
}

export function setRepositOfferResponseModalOpen(payload: boolean) {
  return {
    type: SET_REPOSIT_OFFER_RESPONSE_MODAL_OPEN,
    payload,
  } as const;
}

export function addRejectionReasonRequested(payload: AddRejectionReasonParams) {
  return {
    type: ADD_REJECTION_REASON_API_REQUESTED,
    payload,
  } as const;
}

export function addRejectionReasonSuccess() {
  return {
    type: ADD_REJECTION_REASON_API_SUCCESS,
  } as const;
}

export function addRejectionReasonFailed(error: string) {
  return {
    type: ADD_REJECTION_REASON_API_FAILED,
    payload: error,
  } as const;
}

export type RepositOfferActionTypes = ReturnType<
  | typeof fetchRepositOfferRequested
  | typeof fetchRepositOfferSuccess
  | typeof fetchRepositOfferFailed
  | typeof respondToRepositOfferRequested
  | typeof respondToRepositOfferSuccess
  | typeof respondToRepositOfferFailed
  | typeof setRepositOfferResponseModalOpen
  | typeof addRejectionReasonRequested
  | typeof addRejectionReasonSuccess
  | typeof addRejectionReasonFailed
>;
