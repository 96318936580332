import { AddRejectionReasonRequestDTORejectionReasonEnum } from '@reposit/api-client/dist';
import { Form as FormikForm, Formik } from 'formik';
import { Location } from 'history';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';
import Button from '../../components/Button';
import { RadioGroup } from '../../components/FormFields';
import FormErrorMessage from '../../components/FormFields/ErrorMessage';
import FieldWithLabel from '../../components/FormFields/FieldWithLabel';
import TextArea from '../../components/FormFields/TextArea';
import { Header2, P1, P2 } from '../../components/Typography';
import { useQuery } from '../../hooks/useQuery';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { addRejectionReasonRequested, ADD_REJECTION_REASON_STORE_KEY } from '../../redux/reposit-offer/reposit-offer.actions';

const TOKEN_QUERY = 'token';
const MAX_CHARACTERS = 500;

const Form = styled(FormikForm)`
  margin-top: 32px;
`;

const validationSchema = Yup.object().shape({
  rejectionReason: Yup.string()
    .oneOf(Object.values(AddRejectionReasonRequestDTORejectionReasonEnum))
    .required('Please select a reason'),
  customRejectionReason: Yup.string()
    .when('rejectionReason', {
      is: AddRejectionReasonRequestDTORejectionReasonEnum.OTHER,
      then: (schema: Yup.StringSchema) => schema.required('Please provide more details about your reason'),
      otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
    })
    .max(MAX_CHARACTERS, `Please keep your response to ${MAX_CHARACTERS} characters or less`),
});

interface RepositOfferRejectedFormValues {
  rejectionReason: AddRejectionReasonRequestDTORejectionReasonEnum;
  customRejectionReason?: string;
}

interface RejectReason {
  value: AddRejectionReasonRequestDTORejectionReasonEnum;
  label: string;
}

interface RepositOfferRejectedProps {
  match: any;
  location: Location<any>;
}

export const RepositOfferRejected: React.FC<RepositOfferRejectedProps> = ({ match, location }) => {
  const dispatch = useDispatch();
  const { repositOfferId } = match.params;
  const query = useQuery(location);
  const token = query && query.get(TOKEN_QUERY);

  const isAddRejectionReasonSubmittingSelector = createLoadingSelector([ADD_REJECTION_REASON_STORE_KEY]);
  const isAddRejectionReasonSubmitting = useSelector(isAddRejectionReasonSubmittingSelector);

  if (!token) {
    return <Redirect to="/" />;
  }

  const initialValues: RepositOfferRejectedFormValues = {
    rejectionReason: '' as AddRejectionReasonRequestDTORejectionReasonEnum,
    customRejectionReason: '',
  };

  const rejectionReasons: RejectReason[] = [
    {
      value: AddRejectionReasonRequestDTORejectionReasonEnum.TOOEXPENSIVE,
      label: 'The fee is too expensive',
    },
    {
      value: AddRejectionReasonRequestDTORejectionReasonEnum.DONTUNDERSTAND,
      label: "I don't understand Reposit",
    },
    {
      value: AddRejectionReasonRequestDTORejectionReasonEnum.NONREFUNDABLEFEE,
      label: "I don't want to pay a non-refundable fee",
    },
    {
      value: AddRejectionReasonRequestDTORejectionReasonEnum.OTHER,
      label: 'Other',
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col>
          <Header2 style={{ fontSize: '1.7em' }}>You have decided to proceed with a cash deposit on your new tenancy. </Header2>
          <P1 style={{ fontSize: '1.4em', marginTop: 24 }}>
            If you wish to proceed with Reposit and save money upfront, please get in contact with your agent.
          </P1>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data) => {
              const { rejectionReason, customRejectionReason } = data;
              dispatch(
                addRejectionReasonRequested({
                  repositOfferId,
                  rejectionReason,
                  customRejectionReason,
                  token,
                })
              );
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <P1 style={{ fontSize: '1.2em', marginBottom: 16 }}>
                    Please help us understand why you chose not to proceed with Reposit:
                  </P1>

                  <FieldWithLabel label="Reason" error={errors.rejectionReason} touched={touched.rejectionReason}>
                    <RadioGroup
                      name="rejectionReason"
                      selected={values.rejectionReason}
                      options={rejectionReasons}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      direction="vertical"
                    />

                    <P2>Is there anything else you would like to add?</P2>
                    <TextArea
                      name="customRejectionReason"
                      value={values.customRejectionReason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.customRejectionReason}
                      touched={touched.customRejectionReason}
                    />
                    {touched.customRejectionReason && errors.customRejectionReason ? (
                      <FormErrorMessage error={errors.customRejectionReason} />
                    ) : null}
                  </FieldWithLabel>
                  <Button buttonType="primary" disabled={!values.rejectionReason || isAddRejectionReasonSubmitting} type="submit">
                    Submit
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};
