import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Header2, P1 } from '../../components/Typography';

export const RepositOfferReasonGiven: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Header2 style={{ fontSize: '1.7em' }}>Thank you for providing us with feedback.</Header2>
          <P1 style={{ fontSize: '1.4em', marginTop: 24 }}>
            We appreciate you taking the time to share your feedback with us. Your input helps us make Reposit better for
            everyone.
          </P1>
        </Col>
      </Row>
    </Container>
  );
};
